<template>
    <div class="about">
        <header-layout class="h" :no_bg="true"/>
        <div class="main">
            <head-layout class="he" :text="'Terms And Conditions'"/>
            <div class="terms">

            <div class="text">
                Terms of Service for Nigeria.
            </div>
            <div class="text">Please read them carefully.</div>
            <div class="text">Last Updated: February 10, 2022</div>
            <div class="text">Thank you for using BiggLandlord!</div>

            <div class="para">
                These Terms of Service (“Terms”) are a binding legal agreement between you and 
                BiggLandlordthat govern your right to use the websites, applications, and other 
                offerings from BiggLandlord(collectively, the “BiggLandlord Platform”). 
                When used in these Terms, “BiggLandlord ,” “we,” “us,” or “our” refers to the 
                BiggLandlord entity set out on Schedule 1 with whom you are contracting.
            </div>
            <div class="para">
                The BiggLandlord Platform offers an online venue that enables users (“Members”) 
                to publish, offer , search for, and book services. Members who publish and offer 
                property for sale,let, rent, lease fall into 3 categories “Landlords”, “Agents” & 
                “Partners” and Members who search for, book,buy or patronise “Landlords”, “Agents” & 
                “Partners” are called “clients.” . You must register an account to access and use 
                many features of the BiggLandlord Platform, and must keep your account information 
                accurate. As the provider of the BiggLandlord Platform, BiggLandlord does not own, 
                control, offer or manage any Listings. BiggLandlord is not a party to the contracts 
                entered into directly between “Landlords”, “Agents” & “Partners”  and “clients.” , 
                nor is BiggLandlord a real estate broker. BiggLandlord is not acting as an agent, 
                an employee, joint venturer, or partner in any capacity for any Member, except as 
                specified in the Payments Terms of Service (“Payment Terms”). To learn more about 
                BiggLandlord’s role see Section
            </div>
            <div class="para">
                We maintain other terms and policies that supplement these Terms like our Privacy Policy, 
                which describes our collection and use of personal data, and our Payments Terms, 
                which govern any payment services provided to Members by the BiggLandlord payment 
                entities (collectively "BiggLandlord Payments").
            </div>
            <div class="para">
                If you are a registered user, you are responsible for understanding and complying with all 
                laws, rules, regulations and contracts with third parties as it applies to you/the property.
            </div>

            <div class="big">Table of Contents</div>
            <div class="title">Client Terms</div>
            <div class="list">1. Our Mission.</div>
            <div class="list">2. Searching,property Booking,inspection & acquisition(rent/purchase) on BiggLandlord.</div>
            <div class="list">3. Cancellations, Refunds and booking/rent/purchase modifications/arrangements</div>
            <div class="list">4. Your Responsibilities and Assumption of Risk.</div>

            <div class="title">Landlord and or Agent Terms</div>
            <div class="list">5. Listing on BiggLandlord.</div>
            <div class="list">6. Managing Your Listing.</div>
            <div class="list">7. Cancellations, Refunds and Booking Modifications.</div>
            <div class="list">8. Taxes.</div>

            <div class="title">General Terms</div>
            <div class="list">9. Reviews.</div>
            <div class="list">10. Content.</div>
            <div class="list">11. Fees.</div>
            <div class="list">12. BiggLandlord Platform Rules.</div>
            <div class="list">13. Termination, Suspension and other Measures.</div>
            <div class="list">14. Modification.</div>
            <div class="list">15. Resolving Complaints and Damage Claims.</div>
            <div class="list">16. BiggLandlord’s Role.</div>
            <div class="list">17. Member Accounts.</div>
            <div class="list">18. Disclaimer of Warranties.</div>
            <div class="list">19. Limitations on Liability.</div>
            <div class="list">20. Indemnification.</div>
            <div class="list">21. Contracting Entities.</div>
            <div class="list">22. Rest of World Dispute Resolution, Venue and Forum, and Governing Law.</div>
            <div class="list">23. Miscellaneous.</div>
            
            <div class="para">Schedule 1 - Contracting Entities</div>
            <div class="title">Client Terms</div>

            <div class="label">1. Our Mission.</div>
            <div class="para">
                Our mission is to create a world where you can belong anywhere through provision of 
                property on-demand. From workspaces to castles to shared apartments, browse through 
                millions of Listings to find the ones that fits your budget & lifestyle. Learn more 
                about a Listing by reviewing the description and photos, the Partner/Agent/Landlord, 
                and clients reviews…If you have any questions, contact Us directly via customer care 
                number, e-mail or in-app chat.
            </div>

            <div class="label">2. Searching,property Booking,inspection & acquisition(rent/purchase) on BiggLandlord.</div>
            <div class="para">
                2.1 Searching. You can search for properties offered by landlord and or their Agents & 
                partners by using criteria like the type of property & location. You can also use 
                filters to refine your search results. Search results are based on their relevance to 
                your search and other criteria. Relevance considers factors like price, availability, 
                Reviews, customer service.
            </div>
            <div class="para">
                2.2 Booking. When you book a Listing, you are agreeing to bear financial implication of 
                inspection. Like BiggLandlord’s service fee, offline fees, taxes, and any other items 
                identified during checkout/final conclusion/payment for the property. 
                (collectively, “Total Price”).
            </div>
            <div class="para">
                2.3 Property Acquisition(Rent/purchase). When you inspect and pay for an apartment 
                through Us , you give Us exclusive right to handle payments on your behalf. 
                And that, payment made without us being carried along means,the 10% & 5% service fee 
                will be paid for rentals & purchases respectively to Us . Also you will bear all 
                litigation cost incurred by “us” in the course off getting you to pay.
            </div>
            <div class="para">
                2.4 Property Validation & Landlord vetting. You are responsible for vetting all property 
                documents via all means within your disposal especially during property purchase & 
                landlord vetting via reviews gotten on our platform and or offline.
            </div>

            <div class="label">3. Cancellations, Refunds and Booking Modifications.</div>
            <div class="para">
                3.1 Cancellations. You may decide to cancel your tenancy within 12 hours of payment for 
                an apartment or property for rent in which case you agree to be treated in accordance 
                with the cancellation policy of the landlord. In the event that you paid through Us, 
                We will refund you in full ex a 2% service charge.
            </div>
            <div class="para">
                3.2 Booking Modifications. Clients and Landlord/agents/partners are responsible for any 
                booking/rent/purchase modifications/arrangements they agree to make via the BiggLandlord 
                Platform or direct BiggLandlord customer service to make on their behalf 
                ("booking/rent/purchase modifications/arrangements"), and agree to pay any additional 
                amounts, fees, or taxes associated with any booking/rent/purchase 
                modifications/arrangements.
            </div>

            <div class="label">4. Your Responsibilities and Assumption of Risk.</div>
            <div class="para">
                4.1 Your Responsibilities. You are responsible and liable for your own acts and 
                omissions and are also responsible for the acts and omissions of anyone you invite 
                to join or provide access to any Accommodation, Experience or other Landlord 
                property/premises. For example, this means: (i) you are responsible for leaving an 
                Accommodation (and related personal property) in the condition it was in when you 
                arrived, (ii) you are responsible for paying all reasonable Damage Claim amounts 
                necessary to cover damage that you, your guest(s), or your pet(s) cause to an 
                Accommodation, and (iii) you must act with integrity, treat others with respect, 
                and comply with applicable laws at all times. If you are booking for an additional 
                guest who is a minor or if you bring a minor to a Landlord property/premises, 
                you must be legally authorized to act on behalf of the minor and you are solely 
                responsible for the supervision of that minor.
            </div>
            <div class="para">
                4.2 Your Assumption of Risk. You acknowledge that many activities carry inherent risks 
                and agree that, to the maximum extent permitted by applicable law, you assume the 
                entire risk arising out of your access to and use of the BiggLandlordnPlatform and 
                any Content (as defined in Section 10), including your stay at any Accommodation, 
                participation in any Experience, use of any other Landlord property/premises, or any 
                other interaction you have with other Members whether in person or online. 
                This means it is your responsibility to investigate a Landlord property to determine 
                whether it is suitable for you. For example, Landlord property/premises may carry 
                risk of illness, bodily injury, disability, or death, and you freely and willfully 
                assume those risks by choosing to dwell in the Landlord property/premises
            </div>

            <div class="title">Landlord/Agent/Partner Terms</div>
            <div class="label">5. Listing on BiggLandlord.</div>
            <div class="para">
                5.1 Landlord/Agent/Partner. As a Landlord/Agent/Partner, BiggLandlord offers you the 
                right to use the BiggLandlordPlatform to share your Accommodation, offer for sale or 
                rent your property,   - and earn money doing it. It’s easy to create a Listing and 
                you are in control of how you rent/sell/lease - set your price, availability, and 
                rules for each Listing.
            </div>
            <div class="para">
                5.2 Contracting with Clients. When you accept a booking request, or receive a 
                booking/inspection confirmation through the BiggLandlord Platform, you are entering 
                into a contract directly with the Client, and are responsible for delivering your 
                Landlord/Agent/Partner Service under the terms and at the price specified in your 
                Listing. You are also agreeing to pay applicable fees like BiggLandlord’s service 
                fee (and applicable taxes) for each successful transaction. BiggLandlord Payments 
                will deduct amounts you owe from your payout or you pay our due service fee directly 
                to our bank out. We will use all legal means to retrieve our service fee in cases of 
                default and note that the fees involved will the borne by you . Any terms, policies 
                or conditions that you include in any supplemental contract with Guests must: (i) 
                be consistent with these Terms, our Additional Legal Terms, Policies, and the 
                information provided in your Listing, and (ii) be prominently disclosed in your 
                Listing description.
            </div>
            <div class="para">
                5.3 Independence of Landlord/Agent/Partner . Your relationship with BiggLandlord is 
                that of an independent individual or entity and not an employee, agent, joint venturer, 
                or partner of BiggLandlord, except that BiggLandlord Payments acts as a payment 
                collection agent as described in the Payments Terms. BiggLandlord does not direct or 
                control your Landlord/Agent/Partner Service, and you agree that you have complete 
                discretion whether and when to provide Landlord/Agent/Partner Services, and at what 
                price and on what terms to offer them.
            </div>

            <div class="title">6. Managing Your Listing.</div>
            <div class="para">
                6.1 Creating and Managing Your Listing. The BiggLandlord Platform provides tools that 
                make it easy for you to set up and manage a Listing. Your Listing must include complete 
                and accurate information about your Landlord/Agent/Partner service, your price, other 
                charges like cleaning fees, resort fees, offline fees, and any rules or requirements 
                that apply to your Guests/Tenants or Listing. You are responsible for keeping your 
                Listing information (including calendar availability) and content (like photos) 
                up-to-date and accurate at all times. We recommend that you obtain appropriate 
                insurance for your Landlord/Agent/Partner Services and suggest you carefully review 
                policy terms and conditions including coverage details and exclusions. You may only 
                maintain one Listing per Accommodation/space, but may have multiple Listings for a 
                single property if it has multiple places to stay. You may post as many property 
                for sale, rent, lease or shortlet or sale as long as you are the landlord, landlord´s 
                direct agent or acting directly for the landlord direct agent.
            </div>
            <div class="para">
                6.2 Know Your Legal Obligations. You are responsible for understanding and complying 
                with any laws, rules, regulations, and contracts with third parties that apply to 
                your Listing or Host Services. For example: Some landlords and leases, or homeowner 
                and condominium association rules, restrict or prohibit subletting, short-term 
                rentals and/or longer-term stays. Some cities have zoning or other laws that 
                restrict the short-term rental of residential properties. Some jurisdictions 
                require Landlord/Agent/Partner to register, get a permit, or obtain a license 
                before providing certain Landlord/Agent/Partner Services (such as short-term rentals, 
                longer-term stays, preparing food, serving alcohol for sale, guiding tours, or 
                operating a vehicle). In some places, the Landlord/Agent/Partner Services you want 
                to offer may be prohibited altogether. Some jurisdictions require that you register 
                Clients who stay at your Accommodation. Some jurisdictions have laws that create 
                tenancy rights Clients and additional obligations for Landlord/Agent/Partners. 
                For example, some places have landlord-tenant, rent control, and eviction laws that 
                may apply to longer stays. Check your local rules to learn what rules apply to the 
                Landlord/Agent/Partner  Services you plan to offer. Information we provide regarding 
                legal requirements is for informational purposes only and you should independently 
                confirm your obligations. You are responsible for handling and using personal data 
                of Guests and others in compliance with applicable privacy laws and these Terms, 
                including our Landlord/Agent/Partner  Privacy Standards. If you have questions about 
                how local laws apply you should always seek legal advice.
            </div>
            <div class="para">
                <div>
                    6.3 Search Ranking. The ranking of Listings in search results on the BiggLandlord 
                    Platform depends on a variety of factors, including these main parameters:
                </div>
                <div>
                    Client search parameters (e.g. number of views per time,affordability,location, price range),
                </div>
                <div>
                    Search results may appear different on our mobile application than they appear on 
                    our website. BiggLandlord may allow Landlord/Agent/Partner to promote their 
                    Listings in search or elsewhere on the BiggLandlord Platform by paying an additional 
                    fee. More information about the factors that determine how your Listing appears 
                    in search results, our current promotional programs (if any), and how we identify 
                    promoted Content can be found in our Help Center.
                </div>
            </div>
            <div class="para">
                6.4 Your Responsibilities. You are responsible and liable for your own acts and 
                omissions and are also responsible for the acts and omissions of anyone you allow to 
                participate in providing your Landlord/Agent/Partner  Services. You are responsible 
                for setting your price and establishing rules and requirements for your Listing. 
                You must describe any and all fees and charges in your Listing description and may 
                not collect any additional fees or charges outside the BiggLandlordPlatform except 
                those expressly authorized by our Offline Fee Policy. Do not encourage Clients to 
                create third-party accounts, submit reviews, provide their contact information, 
                or take other actions outside the BiggLandlordPlatform in violation of our Off-Platform 
                Policy.
            </div>
            <div class="para">
                6.5 Landlord/Agent/Partner as a Team or Organization. If you work with an entity/entities 
                as part of a team, business, or other organization, the entity and each individual who 
                participates in providing Landlord/Agent/Partner Services is responsible and liable as 
                a Landlord/Agent/Partner under these Terms. If you accept terms or enter into contracts, 
                you represent and warrant that you are authorized to enter into contracts for and bind 
                your team, business or other organization, and that each entity you use is in good 
                standing under the laws of the place where it is established. If you perform other 
                functions, you represent and warrant that you are authorized to perform those functions. 
                If you instruct BiggLandlord to transfer a portion of your payout to a co-host or other 
                Landlord/Agent/Partner, or to send payments to someone else, you must be authorized 
                to do so, and are responsible and liable for the payment amounts and accuracy of any 
                payout information you provide.
            </div>
            <div class="para">
                6.6 Your Assumption of Risk. You acknowledge that Using our platform carries inherent 
                risks and agree that you assume the entire risk arising out of your access to and use 
                of the BiggLandlord Platform, offering Landlord/Agent/Partner Services, or any 
                interaction you have with other Members whether in person or online. You agree 
                that you have had the opportunity to investigate the BiggLandlord Platform and 
                any laws, rules, regulations, or obligations that may be applicable to your 
                Listings or Landlord/Agent/Partner Services and that you are not relying upon any 
                statement of law made by BiggLandlord.
            </div>


            <div class="label">7. Cancellations, Travel Issues, and Booking Modifications.</div>
            <div class="para">
                7.1 Cancellations of booking and tenancy. In general, if a Client cancels a 
                tenancy/booking within 12hours of payment in full, full refund should be made to 
                him/her ex payment refund costs by the Landlord/Agent/Partner should the payment 
                be received via offline mode. You should not cancel on a tenant without a valid 
                reason under our Extenuating Circumstances Policy or applicable law. If you cancel 
                on a Tenant without such a valid reason, we expect full refund.
            </div>
            <div class="para">
                7.2 Booking Modifications.  Landlord/Agent/Partner and Clients are responsible for 
                any Booking/rent/purchase Modifications/arrangements they agree to make via the 
                BiggLandlord Platform or direct BiggLandlord customer service to make on their behalf, 
                and agree to pay any additional amounts, fees or taxes associated with a Booking 
                Modification.
            </div>

            <div class="label">8. Taxes.</div>
            <div class="para">
                8.1 Landlord/Agent/Partner Taxes. As a  Landlord/Agent/Partner, you are responsible 
                for determining and fulfilling your obligations under applicable laws to report, 
                collect, remit, or include in your price any applicable VAT or other indirect taxes, 
                occupancy taxes, tourist, income, or other taxes ("Taxes").
            </div>
            <div class="para">
                8.2 Collection and Remittance by BiggLandlord . In jurisdictions where BiggLandlord 
                facilitates the collection and/or remittance of Taxes on behalf of Hosts, you instruct 
                and authorize BiggLandlord to collect Taxes on your behalf, and/or to remit such 
                Taxes to the relevant Tax authority. Any Taxes that are collected and/or remitted by 
                BiggLandlord are identified to Members on their transaction records, as applicable. 
                BiggLandlord may seek additional amounts from Members (including by deducting such 
                amounts from future payouts) in the event that the Taxes collected and/or remitted 
                are insufficient to fully discharge that Members’ tax obligations, and you agree 
                that your sole remedy for Taxes collected by BiggLandlord is a refund from the 
                applicable Tax authority. You acknowledge and agree that we retain the right, 
                with prior notice to affected Members, to cease the collection and remittance 
                of Taxes in any jurisdiction for any reason.
            </div>
            <div class="para">
                8.3 Tax Information. In certain jurisdictions, Tax regulations may require that we 
                collect and/or report Tax information about you, or withhold Taxes from payouts to you, 
                or both. If you fail to provide us with documentation that we determine to be 
                sufficient to support any such obligation to withhold Taxes from payouts to you, 
                we may withhold payouts up to the amount as required by law, until sufficient 
                documentation is provided. You agree that BiggLandlord may issue on your behalf 
                invoices or similar documentation for VAT, GST, consumption or other Taxes for your 
                Host Services to facilitate accurate tax reporting by you, our Clients, and/or their 
                organizations.
            </div>

            <div class="title">General Terms</div>

            <div class="label">9. Reviews.</div>
            <div class="para">
                After each  Landlord/Agent/Partner Service,  Landlord/Agent/Partner and clients will 
                have an opportunity to review each other. Your Review must be accurate and may not 
                contain any discriminatory, offensive, defamatory, or other language that violates 
                our Content Policy or Review Policy. Reviews are not verified by BiggLandlord for 
                accuracy and may be incorrect or misleading.
            </div>

            <div class="label">10. Content.</div>
            <div class="para">
                Parts of the BiggLandlord Platform enable you to provide feedback, text, photos, audio, 
                video, information, and other content (collectively, “Content”). By providing Content, 
                in whatever form and through whatever means, you grant BiggLandlord a non-exclusive, 
                worldwide, royalty-free, irrevocable, perpetual, sub-licensable and transferable license 
                to copy, modify, prepare derivative works of, distribute, publish and otherwise exploit, 
                that Content, without limitation. If Content includes personal information, our Privacy 
                Policy describes how we use that personal information. Where BiggLandlord pays for the 
                creation of Content or facilitates its creation, BiggLandlord may own that Content, 
                in which case supplemental terms or disclosures will say that. You are solely responsible 
                for all Content that you provide and warrant that you either own it or are authorized 
                to grant BiggLandlord the rights described in these Terms. You are responsible and 
                liable if any of your Content violates or infringes the intellectual property or 
                privacy rights of any third party. Content must comply with our Content Policy and 
                Nondiscrimination Policy, which prohibit, among other things, discriminatory, obscene, 
                harassing, deceptive, violent, and illegal content. You agree that BiggLandlord may 
                make available services or automated tools to translate Content and that your Content 
                may be translated using such services or tools. BiggLandlord does not guarantee the 
                accuracy or quality of translations and Members are responsible for confirming the 
                accuracy of such translations.
            </div>
            <div class="label">11. Fees.</div>
            <div class="para">
                BiggLandlord may charge fees (and applicable Taxes) to  Landlord/Agent/Partner and 
                Clients for the right to use the BiggLandlord Platform. More information about when 
                service fees apply and how they are calculated can be found on our Service Fees page. 
                Except as otherwise provided on the BiggLandlord Platform, service fees are non-refundable. 
                BiggLandlord reserves the right to change the service fees at any time, and will provide 
                Members notice of any fee changes before they become effective. Fee changes will not affect 
                bookings/transaction/payments made in full prior to the effective date of the fee change. 
                If you disagree with a fee change you may terminate this agreement at any time pursuant to 
                Section 13.2.
            </div>
            <div class="label">12. BiggLandlord Platform Rules.</div>
            <div class="para">
                12.1 Rules. You must follow these rules and must not help or induce others to break or 
                circumvent these rules.
            </div>
            <div class="list">
                Act with integrity and treat others with respect
            </div>
            <div class="list">
                Do not lie, misrepresent something or someone, or pretend to be someone else.
            </div>
            <div class="list">
                Be polite and respectful when you communicate or interact with others.
            </div>
            <div class="list">Follow our Nondiscrimination Policy and do not discriminate against or harass others.</div>
            <div class="list">Do not scrape, hack, reverse engineer, compromise or impair the BiggLandlord Platform</div>
            <div class="list">
                Do not use bots, crawlers, scrapers, or other automated means to access or collect data 
                or other content from or otherwise interact with the BiggLandlord Platform.
            </div>
            <div class="list">
                Do not hack, avoid, remove, impair, or otherwise attempt to circumvent any security or 
                technological measure used to protect the BiggLandlord Platform or Content.
            </div>
            <div class="list">
                Do not decipher, decompile, disassemble, or reverse engineer any of the software or hardware 
                used to provide the BiggLandlord Platform.
            </div>
            <div class="list">
                Do not take any action that could damage or adversely affect the performance or proper functioning of 
                the BiggLandlord Platform.
            </div>
            <div class="list">
                Only use the BiggLandlord Platform as authorized by these Terms or another agreement with us
            </div>
            <div class="list">
                You may only use another Member’s personal information as necessary to facilitate a transaction using the BiggLandlord Platform as authorized by these Terms.
            </div>
            <div class="list">
                Do not use the BiggLandlord Platform, our messaging tools, or Members’ personal information to send commercial messages without the recipient’s express consent.
            </div>
            <div class="list">
                You may use Content made available through the BiggLandlord Platform solely as necessary to enable your use of the BiggLandlord Platform as a Guest or Host.
            </div>
            <div class="list">
                Do not use Content unless you have permission from the Content owner or the use is authorized by us in these Terms or another agreement you have with us.
            </div>
            <div class="list">
                Do not request, make, or accept a booking or any payment outside of the BiggLandlord Platform to avoid paying fees, taxes or for any other reason. 
                See our Offline Fee Policy for exceptions.
            </div>
            <div class="list">
                Do not require or encourage client to open an account, leave a review, complete a survey, 
                or otherwise interact, with a third party website, application or service before, during or 
                after a purchase/transaction, unless authorized by BiggLandlord .
            </div>
            <div class="list">
                Do not engage in any practices that are intended to manipulate our search algorithm.
            </div>
            <div class="list">
                Do not book Landlord/Agent/Partner services unless you are actually using the Services.
            </div>
            <div class="list">
                Do not use, copy, display, mirror or frame the BiggLandlord Platform, any Content, any BiggLandlord branding, or any page layout or design without our consent.
            </div>
            <div class="list">
                Honor your legal obligations
            </div>
            <div class="list">
                Understand and follow the laws that apply to you, including privacy, data protection, and export laws.
            </div>
            <div class="list">
                If you provide us with someone else’s personal information, you: (i) must do so in 
                compliance with applicable law, (ii) must be authorized to do so, and (iii) authorize 
                us to process that information under our Privacy Policy.
            </div>
            <div class="list">
                Read and follow our Terms, Additional Legal Terms, Policies, and Standards.
            </div>
            <div class="list">
                Do not organize or facilitate unauthorized parties or events. You are responsible and 
                liable for any party or event during your Reservation that violates our rules for 
                parties and events, as incorporated by reference herein.
            </div>
            <div class="list">
                Do not use the name, logo, branding, or trademarks of BiggLandlord or others without permission.
            </div>
            <div class="list">
                Do not use or register any domain name, social media handle, trade name, trademark, 
                branding, logo, or other source identifier that may be confused with BiggLandlord branding.
            </div>
            <div class="list">
                Do not offer Landlord/Agent/Partner Services that violate the laws or agreements that apply to you.
            </div>
            <div class="list">
                Do not offer or solicit prostitution or participate in or facilitate human trafficking.
            </div>

            <div class="para">
                12.2 Reporting Violations. If you believe that a Member, Listing or Content poses an 
                imminent risk of harm to a person or property, you should immediately contact local 
                authorities before contacting BiggLandlord. In addition, if you believe that a Member, 
                Listing or Content has violated our Standards, you should report your concerns to 
                BiggLandlord. If you reported an issue to local authorities, BiggLandlord may request 
                a copy of that report. Except as required by law, you agree that we are not obligated 
                to take action in response to any report.
            </div>
            <div class="para">
                12.3 Copyright Notifications. If you believe that Content on the BiggLandlord Platform 
                infringes copyrights, please notify us in accordance with our Copyright Policy.
            </div>

            <div class="label">13. Termination, Suspension and other Measures.</div>
            <div class="para">
                13.1 Term. The agreement between you and BiggLandlord reflected by these Terms is 
                effective when you access the BiggLandlord Platform (for example to create an account) 
                and remains in effect until either you or we terminate the agreement in accordance 
                with these Terms.
            </div>
            <div class="para">
                13.2 Termination. You may terminate this agreement at any time by sending us an email or by 
                deleting your account. BiggLandlord may terminate this agreement and your account for any 
                reason by giving you 30 days’ notice via email or using any other contact information you 
                have provided for your account. BiggLandlord may also terminate this agreement immediately 
                and without notice and stop providing access to the BiggLandlord Platform if you breach these 
                Terms, you violate our Additional Legal Terms, or Policies, you violate applicable laws, 
                or we reasonably believe termination is necessary to protect BiggLandlord , its Members, 
                or third parties. If your account has been inactive for more than two years, we may terminate 
                your account without prior notice.
            </div>
            <div class="para">
                13.3 Member Violations. If (i) you breach these Terms, our Additional Legal Terms, 
                Policies, or our Standards, (ii) you violate applicable laws, regulations, 
                or third-party rights, or (iii) BiggLandlord believes it is reasonably necessary 
                to protect BiggLandlord, its Members, or third parties; BiggLandlord may, with or 
                without prior notice:
            </div>
            <div class="list">
                suspend or limit your access to or use of the BiggLandlord Platform and/or your account;
            </div>
            <div class="list">
                suspend or remove Listings, Reviews, or other Content;
            </div>
            <div class="list">
                cancel pending or confirmed bookings; or
            </div>
            <div class="list">
                suspend or revoke any special status associated with your account.
            </div>
            <div class="list">
                For minor violations or where otherwise appropriate as BiggLandlord determines 
                in its sole discretion, you will be given notice of any intended measure by 
                BiggLandlord and an opportunity to resolve the issue. You may appeal actions 
                taken by us under this Section by contacting customer service.
            </div>

            <div class="para">
                13.4 Legal Mandates. BiggLandlord may take any action it determines is reasonably 
                necessary to comply with applicable law, or the order or request of a court, 
                law enforcement, or other administrative agency or governmental body, including 
                the measures described above in Section 13.3.
            </div>
            <div class="para">
                13.5 Effect of Termination. If you are a Landlord/Agent/Partner and terminate your 
                BiggLandlord account, any confirmed booking(s) will be automatically cancelled and your 
                Clients will receive a full refund. Your property purchase transaction will however be 
                seen through´to the end. If you terminate your account as a Client, any confirmed 
                booking(s) will be automatically cancelled and any refund will depend upon the terms 
                of  your agreement with the Landlord/Agent/Partner. When this agreement has been 
                terminated, you are not entitled to a restoration of your account or any of your 
                Content. If your access to or use of the BiggLandlord Platform has been limited, 
                or your BiggLandlord account has been suspended, or this agreement has been terminated 
                by us, you may not register a new account or access or use the BiggLandlord Platform 
                through an account of another Member.
            </div>
            <div class="para">
                13.6 Survival. Parts of these Terms that by their nature survive termination, 
                will survive termination of this agreement, including Sections 2 through 26.
            </div>

            <div class="label">
                14. Modification.
            </div>
            <div class="para">
                BiggLandlord may modify these Terms at any time. When we make material changes to these 
                Terms, we will post the revised Terms on the BiggLandlord Platform and update the 
                “Last Updated” date at the top of these Terms. We will also provide you with notice of 
                any material changes by email at least 30 days before the date they become effective. 
                If you disagree with the revised Terms, you may terminate this agreement immediately as 
                provided in these Terms. If you do not terminate your agreement before the date the revised 
                Terms become effective, your continued access to or use of the BiggLandlord Platform will 
                constitute acceptance of the revised Terms.
            </div>

            <div class="label">
                15. Resolving Complaints and Damage Claims.
            </div>
            <div class="para">
                If a Landlord/Agent/Partner provides valid evidence that you, your guest(s), or your 
                pet(s)damaged the complaining Member’s real or personal property, or real or personal 
                property the complaining Member is responsible for, including consequential damages, 
                ("Damage Claim"), the complaining Member can notify BiggLandlordand/or seek compensation 
                through the Resolution Center. You will be notified of the Damage Claim and given an 
                opportunity to respond. If you agree to pay, or if the Damage Claim is escalated to 
                BiggLandlord and BiggLandlord determines in its sole discretion that the Damage Claim 
                is valid and you are responsible for the Damage Claim, BiggLandlord via BiggLandlord 
                Payments can collect the amount of the Damage Claim from you. You agree that BiggLandlord 
                may seek to recover from you under any insurance policies you maintain and that 
                BiggLandlord may also pursue against you any remedies it may have available under 
                applicable law, including referral of the matter to a collections agency, and/or 
                pursuit of available causes of action and/or claims against you. You agree to cooperate 
                in good faith, provide any information BiggLandlord requests, execute documents, and 
                take further reasonable action, in connection with Damage Claims, Member complaints, 
                claims under insurance policies, or other claims related to your provision or use of 
                Landlord/Agent/Partner Services.
            </div>

            <div class="label"> 16. BiggLandlord’s Role. </div>
            <div class="para">
                We offer you the right to use a platform that enables Members to publish, offer, 
                search for, and book Landlord/Agent/Partner Services. While we work hard to ensure 
                our Members have great experiences using BiggLandlord, we do not and cannot control 
                the conduct of Guests and Hosts. You acknowledge that BiggLandlord has the right, 
                but does not have any obligation, to monitor the use of the BiggLandlord Platform and 
                verify information provided by our Members. For example, we may review, disable access 
                to, remove, or edit Content to: (i) operate, secure and improve the BiggLandlord 
                Platform (including for fraud prevention, risk assessment, investigation and customer 
                support purposes); (ii) ensure Members’ compliance with these Terms; (iii) comply with 
                applicable law or the order or requirement of a court, law enforcement or other 
                administrative agency or governmental body; (iv) address Content that we determine is 
                harmful or objectionable; (v) take actions set out in these Terms; and (vi) maintain 
                and enforce any quality or eligibility criteria, including by removing Listings that 
                don’t meet quality and eligibility criteria. Members acknowledge and agree that 
                BiggLandlord administers its Additional Legal Terms, Policies (such as our Extenuating 
                Circumstances Policy) and Standards (such as basic requirements for hosts), including 
                decisions about whether and how to apply them to a particular situation, at its sole 
                discretion. Members agree to cooperate with and assist BiggLandlord in good faith, and 
                to provide BiggLandlord with such information and take such actions as may be reasonably 
                requested by BiggLandlord with respect to any investigation undertaken by BiggLandlord 
                regarding the use or abuse of the BiggLandlord Platform. BiggLandlord is not acting as 
                an agent for any Member except for where BiggLandlord Payments acts as a collection 
                agent as provided in the Payments Terms.
            </div>

            <div class="label">17. Member Accounts.</div>
            <div class="para">
                You must register an account to access and use many features of the BiggLandlord 
                Platform. Registration is only permitted for legal entities, partnerships and natural 
                persons who are 18 years or older. You represent and warrant that you are not a person 
                or entity barred from using the BiggLandlordPlatform under the laws of the Federal 
                Republic of Nigeria, your place of residence, or any other applicable jurisdiction. 
                You must provide accurate, current, and complete information during registration and 
                keep your account information up-to-date. You may not register more than one account 
                or transfer your account to someone else. You are responsible for maintaining the 
                confidentiality and security of your account credentials and may not disclose your 
                credentials to any third party. You are responsible and liable for activities conducted 
                through your account and must immediately notify BiggLandlord if you suspect that 
                your credentials have been lost, stolen, or your account is otherwise compromised. 
                If and as permitted by applicable law, we may, but have no obligation to (i) ask you 
                to provide identification or other information, (ii) undertake checks designed to help 
                verify your identity or background, (iii) screen you against third-party databases or 
                other sources and request reports from service providers, and (iv) obtain reports from 
                public records of criminal convictions or sex offender registrations or their local 
                equivalents.
            </div>

            <div class="label"> 18. Disclaimer of Warranties. </div>
            <div class="para">
                We provide the BiggLandlord Platform and all Content “as is” without warranty of any 
                kind and we disclaim all warranties, whether express or implied. For example: (i) 
                we do not endorse or warrant the existence, conduct, performance, safety, quality, 
                legality or suitability of any Guest, Host, Host Service, Listing or third party; 
                (ii) we do not warrant the performance or non-interruption of the BiggLandlord Platform; 
                and (iii) we do not warrant that verification, identity or background checks conducted 
                on Listings or Members (if any) will identify past misconduct or prevent future 
                misconduct. Any references to a Member or Listing being "verified" (or similar language) 
                indicate only that the Member or Listing or BiggLandlord has completed a relevant 
                verification or identification process and nothing else. The disclaimers in these Terms 
                apply to the maximum extent permitted by law. If you have statutory rights or warranties 
                we cannot disclaim, the duration of any such statutorily required rights or warranties, 
                will be limited to the maximum extent permitted by law.
            </div>

            <div class="label">19. Limitations on Liability.</div>
            <div class="para">
                Neither BiggLandlord(including its affiliates and personnel) nor any other party 
                involved in creating, producing, or delivering the BiggLandlord Platform or any 
                Content will be liable for any incidental, special, exemplary or consequential 
                damages, including lost profits, loss of data or loss of goodwill, service interruption, 
                computer damage or system failure or the cost of substitute products or services, 
                or for any damages for personal or bodily injury or emotional distress arising out of 
                or in connection with (i) these Terms, (ii) the use of or inability to use the 
                BiggLandlord Platform or any Content, (iii) any communications, interactions or meetings 
                you may have with someone you interact or meet with through, or as a result of, your 
                use of the BiggLandlord Platform, or (iv) publishing or booking of a Listing, including 
                the provision or use of Landlord/Agent/Partner Services, whether based on warranty, 
                contract, tort (including negligence), product liability or any other legal theory, 
                and whether or not BiggLandlord has been informed of the possibility of such damage, 
                even if a limited remedy set out in these Terms is found to have failed of its essential 
                purpose.
            </div>
            <div class="para">
                All liability claim are entirely discharged according to local laws within the jurisdiction.
            </div>


            <div class="label"> 20. Indemnification. </div>
            <div class="para">
                To the maximum extent permitted by applicable law, you agree to release, defend 
                (at BiggLandlord’s option), indemnify, and hold BiggLandlord(including BiggLandlord 
                Payments, other affiliates, and their personnel) harmless from and against any claims, 
                liabilities, damages, losses, and expenses, including, without limitation, reasonable 
                legal and accounting fees, arising out of or in any way connected with: (i) your breach 
                of these Terms (including any supplemental or additional terms that apply to a product 
                or feature) or our
            </div>
            <div class="para">
                Additional Legal Terms,  Policies or Standards, (ii) your improper use of the 
                BiggLandlord Platform, (iii) your interaction with any Member, stay at an 
                Accommodation7space, including without limitation any injuries, losses or damages 
                (whether compensatory, direct, incidental, consequential or otherwise) of any kind 
                arising in connection with or as a result of such interaction, stay, participation or 
                use, (iv) your failure, or our failure at your direction, to accurately report, 
                collect or remit Taxes, or (v) your breach of any laws, regulations or third party 
                rights such as intellectual property or privacy rights.
            </div>

            <div class="label">21. Contracting Entities.</div>
            <div class="para">
                Based on your country of residence or establishment and what you are doing on the 
                BiggLandlord Platform, Schedule 1 below sets out the BiggLandlord entity with whom 
                you are contracting. If we identify through the BiggLandlord Platform, an BiggLandlord 
                entity other than the one set out on Schedule 1 as being responsible for a product, 
                feature or transaction, the BiggLandlord entity so identified is your contracting entity 
                with respect to that product, feature or transaction. If you change your country of 
                residence or establishment, the BiggLandlord company you contract with (as set out on 
                Schedule 1) and the applicable version of the Terms of Service will be determined by 
                your new country of residence or establishment, from the date on which your country of 
                residence or establishment changes.
            </div>


            <div class="label">
                26. Rest of World Dispute Resolution, Venue and Forum, and Governing Law.. 
                If BiggLandlord wishes to enforce any of its rights against you as a consumer, we may do so 
                only in the courts of the jurisdiction in which you are a resident.
            </div>

            <div class="label">27. Miscellaneous.</div>
            <div class="para">
                27.1 Other Terms Incorporated by Reference. Our Property Damage Protection, 
                Client Refund Policy, Content Policy, Nondiscrimination Policy, Extenuating 
                Circumstances Policy, Additional Legal Terms, Policies, Standards and other 
                supplemental policies and terms linked to in these Terms apply to your use of the 
                BiggLandlord Platform, are incorporated by reference, and form part of your agreement 
                with BiggLandlord.
            </div>
            <div class="para">
                27.2 Interpreting these Terms. Except as they may be supplemented by additional terms, 
                conditions, policies, guidelines, standards, and in-product disclosures, these 
                Terms (including those items incorporated by reference) constitute the entire 
                agreement between BiggLandlordand you pertaining to your access to or use of the 
                BiggLandlord Platform and supersede any and all prior oral or written understandings 
                or agreements between BiggLandlord and you. These Terms do not and are not intended 
                to confer any rights or remedies upon anyone other than you and BiggLandlord. 
                If any provision of these Terms is held to be invalid or unenforceable, except as 
                otherwise indicated in Section 23.11 above, such provision will be struck and will not 
                affect the validity and enforceability of the remaining provisions. Where the word 
                “will” is used in these Terms it connotes an obligation with the same meaning as 
                “shall.”
            </div>
            <div class="para">
                27.3 No Waiver. BiggLandlord ’s failure to enforce any right or provision in these 
                Terms will not constitute a waiver of such right or provision unless acknowledged 
                and agreed to by us in writing. Except as expressly set forth in these Terms, the 
                exercise by either party of any of its remedies under these Terms will be without 
                prejudice to its other remedies under these Terms or otherwise permitted under law.
            </div>
            <div class="para">
                27.4 Assignment. You may not assign, transfer or delegate this agreement or your 
                rights and obligations hereunder without BiggLandlord 's prior written consent. 
                BiggLandlord may without restriction assign, transfer or delegate this agreement and 
                any rights and obligations hereunder, at its sole discretion, with 30 days’ prior notice.
            </div>
            <div class="para">
                27.5 Notice. Unless specified otherwise, any notices or other communications to Members 
                permitted or required under this agreement, will be provided electronically and given 
                by BiggLandlord via email, BiggLandlord Platform notification, messaging service 
                (including SMS, or any other contact method we enable and you provide.
            </div>
            <div class="para">
                27.6 Third-Party Services. The BiggLandlord Platform may contain links to third-party 
                websites, applications, services or resources (“Third-Party Services”) that are subject 
                to different terms and privacy practices. BiggLandlord is not responsible or liable for 
                any aspect of such Third-Party Services and links to such Third-Party Services are not 
                an endorsement.
            </div>
            <div class="para">
                27.7 Google Terms. Some translations on the BiggLandlord Platform are powered by Google. 
                Google disclaims all warranties related to the translations, express or implied, including 
                any warranties of accuracy, reliability, and any implied warranties for merchantability, 
                fitness for a particular purpose and non-infringement. Some areas of the BiggLandlord Platform 
                implement Google Maps/Earth mapping services, including Google Maps API(s). Your use of Google 
                Maps/Earth is subject to the Google Maps/Google Earth Additional Terms of Service.
            </div>
            <div class="para">
                27.8 Apple Terms. If you access or download our application from the Apple App Store, you agree 
                to Apple’s Licensed Application End User License Agreement.
            </div>
            <div class="para">
                27.9 BiggLandlord Platform Content. Content made available through the BiggLandlord 
                Platform may be protected by copyright, trademark, and/or other laws of the United States 
                and other countries. You acknowledge that all intellectual property rights for that 
                Content are the exclusive property of BiggLandlordand/or its licensors and agree that 
                you will not remove, alter or obscure any copyright, trademark, service mark or other 
                proprietary rights notices. You may not use, copy, adapt, modify, prepare derivative 
                works of, distribute, license, sell, transfer, publicly display, publicly perform, 
                transmit, broadcast or otherwise exploit any Content accessed through the BiggLandlord 
                Platform except to the extent you are the legal owner of that Content or as expressly 
                permitted in these Terms. Subject to your compliance with these Terms, BiggLandlord 
                grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable 
                license to (i) download and use the Application on your personal device(s); and (ii) 
                access and view the Content made available on or through the BiggLandlord Platform and 
                accessible to you, solely for your personal and non-commercial use.
            </div>
            <div class="para">
                27.11 Force Majeure. BiggLandlord shall not be liable for any delay or failure to 
                perform resulting from causes outside its reasonable control, including, but not 
                limited to, acts of God, natural disasters, war, terrorism, riots, embargoes, acts of 
                civil or military authorities, fire, floods, accidents, pandemics, epidemics or disease, 
                strikes or shortages of transportation facilities, fuel, energy, labor or materials.
            </div>
            <div class="para">
                27.12 Emails and SMS. You will receive administrative communications from us using the 
                email address or other contact information you provide for your BiggLandlord account. 
                Enrollment in additional email subscription programs will not affect the frequency of 
                these administrative emails, though you should expect to receive additional emails 
                specific to the program(s) to which you have subscribed. You may also receive promotional 
                emails from us. No fee is charged for these promotional emails, but third-party data 
                rates could apply. You can control whether you receive promotional emails using the 
                notification preferences in your account settings. Please note that you will not be 
                able to take advantage of certain promotions if you disable certain communication 
                settings or do not have an BiggLandlord account.
            </div>
            <div class="para">
                27.13 Contact Us. If you have any questions about these Terms please email us.
            </div>
        
            </div>
        </div>
        <register/>
        <footer-layout/>
    </div>
</template>

<script>
import headerLayout from '../components/header.vue'
import headLayout from '../components/head.vue'
import register from '../components/register.vue'
import footerLayout from '../components/footer.vue'

export default {
    components: {
        headerLayout,
        headLayout, register, footerLayout
    },
    
    mounted () {
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>

    .h {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        z-index: 5;
        color: white;
    }
    
    .main {
        max-width: 1000px;
        margin: 0 auto;
        padding: 0px 20px;
        padding-bottom: 70px;
        padding-top: 100px;
    }
    .he {
        margin: 40px 0px;
        margin-top: 20px;
    }

    .terms {
        line-height: 25px;
        font-size: 14px;
    }

    .big {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .label {
        font-weight: 500;
        margin-bottom: 5px;
    }
    .para {
        margin-bottom: 20px;
    }

    .list {
        margin-bottom: 10px;
        margin-left: 10px;
    }
</style>